// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

.calendar {
    width: 100%;
    height: 100%;
    background-color: transparent;

    .content {
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        width: 100%;
        height: 100%;
        padding: 0 0 2rem 2rem;

        .main {
            flex: auto;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .calendar {
        .content {
            padding: 0;
        }
    }
}

@media only screen and (max-width: @small) and (orientation: landscape) {
    .calendar {
        .content {
            padding: 0 0 0 1rem;
        }
    }
}
