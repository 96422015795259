// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

.cell {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    background-color: var(--overlay-color);
    border: 0.15rem solid transparent;
    overflow: hidden;
    cursor: pointer;
    transition: border-color 0.1s ease-out;
    -webkit-tap-highlight-color: transparent;

    &:first-child {
        border-radius: var(--border-radius) 0 0 0;
    }

    &:nth-child(7) {
        border-radius: 0 var(--border-radius) 0 0;
    }

    &:last-child {
        border-radius: 0 0 var(--border-radius) 0;
    }

    .heading {
        flex: none;
        position: relative;
        height: 3rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;

        .day {
            flex: none;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2rem;
            width: 2rem;
            border-radius: 100%;
            font-size: 1rem;
            font-weight: 500;
            color: var(--primary-foreground-color);
        }
    }

    .items {
        flex: 0 1 10rem;
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 0 1rem 1rem 1rem;

        .item {
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            aspect-ratio: 2 / 3;
            border-radius: var(--border-radius);

            .icon {
                flex: none;
                z-index: 1;
                position: absolute;
                width: 2rem;
                height: 2rem;
                padding: 0.5rem;
                border-radius: 50%;
                color: var(--primary-foreground-color);
                background-color: var(--secondary-accent-color);
                opacity: 0;
            }

            .poster {
                flex: auto;
                z-index: 0;
                position: relative;
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: 1;
            }

            .icon, .poster {
                transition: opacity 0.1s ease-out;
            }

            &:hover {
                .icon {
                    opacity: 1;
                }

                .poster {
                    opacity: 0.5;
                }
            }
        }
    }

    .more {
        display: none;
        flex: none;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        align-self: center;
        color: var(--primary-foreground-color);
    }

    &.today {
        .heading {
            .day {
                background-color: var(--primary-accent-color);
            }
        }
    }

    &.active {
        border-color: var(--primary-foreground-color);
    }

    &:not(.active):hover {
        border-color: var(--overlay-color);
    }
}

@media only screen and (orientation: portrait) {
    .cell {
        .heading {
            justify-content: center;
        }

        .items {
            display: none;
        }

        .more {
            display: flex;
        }
    }
}

@media only screen and (max-width: @small) and (orientation: landscape) {
    .cell {
        flex-direction: row;
        align-items: center;

        .items {
           display: none;
        }

        .more {
            display: flex;
        }
    }
}

@media only screen and (max-height: @xxsmall) and (orientation: landscape) {
    .cell {
        .items {
            display: none;
        }

        .more {
            display: flex;
        }
    }
}
