// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @color-background-dark5-40;

    .modal-dialog-container {
        position: relative;
        flex: none;
        display: flex;
        flex-direction: column;
        max-width: 80%;
        max-height: 80%;
        padding: 0 2rem;
        border-radius: var(--border-radius);
        background-color: var(--modal-background-color);
        box-shadow: var(--outer-glow);

        .modal-dialog-background {
            z-index: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-size: cover;
            background-position: center;
            opacity: 0.1;
        }

        .close-button-container {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            width: 3rem;
            height: 3rem;
            padding: 0.5rem;
            border-radius: var(--border-radius);
            z-index: 2;

            .icon {
                display: block;
                width: 100%;
                height: 100%;
                color: var(--primary-foreground-color);
                opacity: 0.4;
            }

            &:hover,
            &:focus {
                .icon {
                    opacity: 1;
                    color: var(--primary-foreground-color);
                }
            }

            &:focus {
                outline-color: var(--primary-foreground-color);
            }
        }

        .modal-dialog-content {
            z-index: 1;
            position: relative;
            overflow-y: auto;

            .title-container {
                flex: 1 0 auto;
                display: flex;
                align-items: center;
                height: 4.5rem;
                font-size: 1.2rem;
                font-weight: 500;
                color: var(--primary-foreground-color);
            }

            .body-container {
                flex: 1;
                align-self: stretch;
                overflow-y: auto;
                padding: 2rem 0;

                &:last-child {
                    margin-bottom: 2rem;
                }
            }

            .buttons-container {
                flex: none;
                align-self: stretch;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                &:last-child {
                    margin: 2rem 0;
                }
            }
        }
    }
}

.action-button {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    border-radius: 3.5rem;
    padding: 1.2rem;
    background-color: var(--secondary-accent-color);

    &:hover {
        background-color: transparent;
        outline: var(--focus-outline-size) solid var(--secondary-accent-color);
    }

    &:focus {
        outline-color: var(--primary-foreground-color);
    }

    &:not(:last-child) {
        margin-right: 1rem;
    }

    .icon {
        flex: none;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: .5rem;
        color: var(--primary-foreground-color);
    }

    .label {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        max-height: 3.6em;
        font-size: 1.1rem;
        font-weight: 700;
        text-align: center;
        color: var(--primary-foreground-color);
    }
}

@media only screen and (max-width: @minimum) {
    .modal-container {
        .modal-dialog-container {
            position: relative;
            width: 90%;
            max-width: initial;
            z-index: 0;
            padding: 0 1.5rem;

            .modal-dialog-content {
                .buttons-container {
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }

        .action-button {
            width: 100%;

            .label {
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}